import 'date-fns';
import { Link } from 'react-router-dom';
import {useEffect, useCallback, useState, useRef} from 'react';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.js';
import Map, {Source, Layer} from 'react-map-gl';


// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function EditRoute() {
  const [isLoading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [route, setRoute] = useState();
  const [action, setAction] = useState('update');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);


  const loadData = useCallback(async () => {    
    try {
      setLoading(true);
      const response = await api.get(`/route/${id}`);
      setRoute(response.data);
 
      const coords = [];
      const coordinates = JSON.parse(response.data.coordinates);

      coordinates.map(c => {
        coords.push([c[0], c[1]]);
      })

      setCoordinates(coords);

      setLoaded(true);
      setLoading(false);

    }
    catch(e){

    }
  },[]);


  const geojson = {
    type: 'FeatureCollection',
    features: [
      {type: 'Feature', geometry: {type: 'LineString', coordinates}}
    ]
  };


  const layerStyle = {
    id: 'point',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#FF0000',
      'line-width': 8
      }
   
  };


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Detalhes Rota - {route?.name}</h4>
            </CardHeader>
            <CardBody>

          
              <p><strong>Nome Rota: {route?.name} </strong></p>

              {coordinates.length > 0 && <Map
                mapboxAccessToken="pk.eyJ1Ijoicm9nZXJpb3dlYiIsImEiOiJjbGcxNjY0eHgwMGhhM2xxdjNyaDBxc3B5In0.KvFLopI6xct709jst-UKQA"
              
                initialViewState={{
                  longitude: coordinates[0][0],
                  latitude: coordinates[0][1],
                  zoom: 13
                }}
                style={{width: '100%', height: 800}}
                mapStyle="mapbox://styles/mapbox/streets-v9"

                
              >
                  {coordinates.length > 0 && <Source id="my-data" type="geojson" data={geojson}>
                    <Layer {...layerStyle} />
                  </Source>}
                </Map>}


              <Link to="/admin/route"> <Button color="info">VOLTAR</Button></Link>
 

            </CardBody>
          
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
