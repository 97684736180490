import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import 'assets/css/react-confirm-alert.css'; // Import css

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { confirmAlert } from 'react-confirm-alert';

import { Pagination } from '@mui/material';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function BlockUserControl() {

  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const {addToast} = useToasts();

  const getData = useCallback(async () => {
    try {

      const response = await api.post("/general/block_user_control/search", {
        filters: {
       
        }
      });

      setTotal(response.data.total);

      const info = [];
 
      response.data.data.map(s => {
        info.push({
          id: s.id,
          user_id: s.user.id,
          user_name: s.user.name,
          status: s.user.status ? 'ACESSO ATIVO' : 'ACESSO BLOQUEADO',
          user_complaint: s.user_complaint.name,
          motivo: s.motivo,
          created_at: new Date(s.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
        })
      })

      setData(info);
      setLoading(false);
    }
    catch(e){
      console.log(e);
      setLoading(false);
    } 
  },[]);

   
  const handleDelete = useCallback(async (id) => {
 
    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover esta reclamação ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });
  });

  const handleBlockUser = useCallback(async (id) => {
 
    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja bloquear este usuario?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postBlockAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });
  });

  const postDeleteAction =  useCallback(async (id) => {
    try {
      await api.delete(`/general/block_control_user/${id}`);
      getData();
 
      
    }
    catch(e){
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });        
    }
},[]);

const postBlockAction =  useCallback(async (id) => {
  try {
    await api.post(`/user/block`, {
      user_id: id
    });
    getData();
    addToast('Acesso do Usuario bloqeuado', {
      appearance: 'success',
      autoDismiss: true,
    });  
    
  }
  catch(e){
    addToast(e.response.data.message, {
      appearance: 'error',
      autoDismiss: true,
    });        
  }
},[]);

   useEffect(() => {
      getData();
   }, [page]);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Reclamação Usuários</h4>
            <p className={classes.cardCategoryWhite}>
              Reclamação de Usuários - Uso indevido
            </p>
          </CardHeader>
          <CardBody>

         
          {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Usuário denunciado', 'Acesso do Usuario', 'Usuário Reclamante', 'Motivo', 'Criado em', '', '']}
              tableColumn={['user_name', 'status', 'user_complaint', 'motivo', 'created_at', 'delete_complaint', 'block_user']}
              tableColumnSize={['30%', '20%', '20%', '20%', '20%', '1%', '1%']}
              tableData={data}        
              onDelete={(id) => {
                handleDelete(id);
              }} 
              onBlockUser={(id) => {
                handleBlockUser(id);
              }} 
             
           
           /> : isLoading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
          ) : (           
            <p style={{marginTop: 20}}>Nenhuma reclamação registrada.</p>
          )}

          {total > 50 && <Pagination page={page} onChange={(ev, page) => {setPage(page)}} count={total} size="large" style={{marginTop:30}}/>}
                    
                    
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
