// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Assignment from '@material-ui/icons/Assignment';
import DirectionsBus from '@material-ui/icons/DirectionsBus';
import Directions from '@material-ui/icons/Directions';
import Group from '@material-ui/icons/Group';
import Notifications from '@material-ui/icons/Notifications';

// core components/views for Admin layout
import AdminPage from '../pages/Admin';
import UserPage from '../pages/User';
import VehicleTypePage from '../pages/VehicleType';
import RoutePage from '../pages/Route';
import GroupPage from '../pages/Group';
import ContentControlPage from '../pages/ContentControl';
import BlockUserControlPage from '../pages/BlockUserControl';

import AlertPage from '../pages/Alert';
import TextPage from '../pages/Texts';

const menuRoutes = [
  {
    path: '/admin',
    name: 'Administradores',
    icon: Person,
    component: AdminPage,
    layout: '/admin',
  },
  {
    path: '/user',
    name: 'Usuários do aplicativo',
    icon: Person,
    component: UserPage,
    layout: '/admin',
  },

  {
    path: '/route',
    name: 'Rotas/Percursos',
    icon: Directions,
    component: RoutePage,
    layout: '/admin',
  },
  {
    path: '/group',
    name: 'Grupos',
    icon: Group,
    component: GroupPage,
    layout: '/admin',
  },
  {
    path: '/block_user_control',
    name: 'Reclamação Usuarios',
    icon: Notifications,
    component: BlockUserControlPage,
    layout: '/admin',
  },
  {
    path: '/content_control',
    name: 'Reclamação Conteúdo',
    icon: Notifications,
    component: ContentControlPage,
    layout: '/admin',
  },
  {
    path: '/alert',
    name: 'Alertas/Notificações',
    icon: Notifications,
    component: AlertPage,
    layout: '/admin',
  },
  //{
  //  path: '/text',
  //  name: 'Textos',
  //  icon: Notifications,
  //  component: TextPage,
  //  layout: '/admin',
  //},


];

export default menuRoutes;
