import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';


import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditUser() {
  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [nickname, setNickname] = useState('');
  const [phone, setPhone] = useState('');
  const [photo, setPhoto] = useState('');
  const [shareLocation, setShareLocation] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [status, setStatus] = useState(false);
  const [password, setPassword] = useState('');
  const [type, setType] = useState('');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'),
      });         

      await schema.validate({name}, {
        abortEarly: false,
      });

      const postData = {  
        name,
        nickname,
        phone,
        password,
        share_location: shareLocation,
        confirmed,
        status,
        type
      };

      if (action === 'update'){
        postData.id = id;
        await api.put("/user/"+id, postData);
      }
      else{
        await api.post("/user", postData);
      }

      history.push('/admin/user');
   
    }
    catch(e){ 


      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      addToast(e.response ? e.response.data?.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
  
    }
  },[action, name, nickname, phone, shareLocation, type, confirmed, status, password]);

  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/user/${id}`);
      setName(response.data.name);
      setNickname(response.data.nickname);
      setType(response.data.type);
      setPhone(response.data.phone);
      setShareLocation(response.data.share_location);
      setConfirmed(response.data.confirmed);
      setStatus(response.data.status);
      setPhoto(response.data.photo);

    }
    catch(e){
   
      addToast(e.response.data?.message ? e.response.data?.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/user");
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do Usuario</h4>
            </CardHeader>
            <CardBody>  

  
             {photo && <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/avatar/${photo}`} width={250}></img>}
           
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required 
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}                     
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required 
                      fullWidth
                      id="nickname"
                      label="Apelido"
                      name="nickname"
                      value={nickname}
                      onChange={(e) => setNickname(e.target.value)}                     
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required 
                      fullWidth
                      id="phone"
                      label="Telefone"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}                     
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 20, marginBottom: 15}}>
                <InputLabel>Tipo Usuario</InputLabel>             
                  <Select 
                        labelId="type"
                        label="Tipo Usuário"
                        variant="outlined"                  
                        name="type"
                        fullWidth
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        >     
                          <MenuItem value="U">
                          USUARIO
                          </MenuItem> 
                          
                          <MenuItem value="A">
                            ADMIN
                          </MenuItem>   
                                                  
                  </Select>
              </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required={false}
                      fullWidth
                      id="password"
                      label="Senha"
                      name="password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}  
                     
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <Checkbox
                   checked={
                    status
                    }                                                  
                    onChange={(val) => {                      
                      setStatus(!status);
                    }}            
                   /> ATIVO<br />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                <Checkbox
                   checked={
                    confirmed
                    }                                                  
                    onChange={(val) => {                      
                      setConfirmed(!confirmed);
                    }}            
                   /> CADASTRO CONFIRMADO<br />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                <Checkbox
                   checked={
                    shareLocation
                    }                                                  
                    onChange={(val) => {                      
                      setShareLocation(!shareLocation);
                    }}            
                   /> COMPARTILHANDO LOCALIZAÇÃO<br />
                </GridItem>

             
              </GridContainer>

           
             
            </CardBody>
            <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
              <Link to="/admin/user"> <Button color="info">VOLTAR</Button></Link>
 
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
