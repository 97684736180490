import React, { useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from "@material-ui/core/Divider";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';
import { Pagination } from '@mui/material';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Route() {

  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { addToast } = useToasts();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');

  useEffect(() => {
    getData();
 }, [page]);

 const getUsers = useCallback(async () => {
  try {

    const response = await api.post("/user/search", {
      filters: {
        order: 'ordem_alfabetica',       
      }
    });

    setTotal(response.data.total);

    const users = [];

    response.data.users.map(s => {
      users.push({
        id: s.id,
        name: s.name,   
        nickname: s.nickname      
      })
    })

    setUsers(users);
    setLoading(false);
  }
  catch(e){} 
},[]);

 const handleDelete = useCallback(async (id) => {

  confirmAlert({
    title: 'Confirmação',
    message: 'Tem certeza que deseja remover ?',
    buttons: [
      {
        label: 'Sim',
        onClick: () => postDeleteAction(id)
      },
      {
        label: 'Não'         
      }
    ]
  });

},[]);

const postDeleteAction =  useCallback(async (id) => {
    try {
        await api.delete(`/route/${id}`);
        setData(data => data.filter(d => d.id != id));
    }
    catch(e){
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });        
    }
},[]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.post("/route/search", {
        filters: {
          user_id: user || null
        }
      });
      const routes = [];
      response.data.routes.map(r => {
   
        routes.push({
          id: r.id,
          name: r.name,   
          user: r.user?.name || 'TODOS',
          distance: r.distance + ' km',
          created_at: new Date(r.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          }),
          type: r.type === 'automatic' ? 'Automatica' : 'Manual'
        })
      });

      setTotal(response.data.total);
      setData(routes);
      setLoading(false);
    }
    catch(e){

      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });     
      setLoading(false);   
    } 
  },[user]);

  useEffect(() => {
    getUsers();
  },[]);
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Rotas/Percursos</h4>
            <p className={classes.cardCategoryWhite}>
              Lista das rotas/percursos cadastrados
            </p>
          </CardHeader>
          <CardBody>


          <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 5}}>
            <InputLabel>Usuario</InputLabel>             
               <Select 
                    labelId="user"
                    label="Usuário"
                    variant="outlined"                  
                    name="user"
                    fullWidth
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    >     
                     <MenuItem value="T">
                       TODOS
                      </MenuItem> 
                      {users && users.map(u => 
                      <MenuItem value={u.id}>
                       {u.name} - {u.nickname}
                      </MenuItem>)
                      }                      
                                              
              </Select>
           </FormControl>

           <Button color="primary" type="button" style={{marginTop: 20}} onClick={() => {
            if (page>1){
              setPage(1);
            } else {
              getData();
            }
          }}>Filtrar</Button>

          <Divider style={{marginTop: 20, marginBottom: 20 }} />
          <Link to="/admin/route/manual"> <Button color="info">CADASTRAR ROTA MANUAL</Button></Link>
          <Link to="/admin/route/automatic"> <Button color="danger">CADASTRAR ROTA AUTOMÁTICA</Button></Link>
          

          {total > 50 && <Pagination page={page} onChange={(ev, page) => {setPage(page)}} count={total} size="large" style={{marginTop:30}}/>}    
     

          {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Rota/Percurso', 'Tipo', 'Usuário', 'Distancia', 'Cadastrado em', '', '', '']}
              tableColumn={['name', 'type', 'user', 'distance', 'created_at', 'edit', 'delete']}
              tableColumnSize={['20%', '20%', '20%', '20%', '20%', '1%', '1%']}
              tableData={data} 
              resource="route"            
              onDelete={(id) => handleDelete(id)}
           
           /> : isLoading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
          ) : (           
            <p style={{marginTop: 20}}></p>
          )}

          {total > 50 && <Pagination page={page} onChange={(ev, page) => {setPage(page)}} count={total} size="large" style={{marginTop:30}}/>}    
     

          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
