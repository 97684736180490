import 'date-fns';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Button from 'components/CustomButtons/Button.js';
import { useToasts } from 'react-toast-notifications'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function AddRouteManual() {

  const classes = useStyles();
  const { addToast } = useToasts()
  const history = useHistory();

  useEffect(() => {
    const handler = async event => {
      try {
        const data = JSON.parse(event.data);
        const coordinates = data.coordinates;
        const distance = data.distance;
        if (coordinates) {
          let routeName = prompt("Informe o nome da rota", "");
          addRoute(coordinates, routeName, distance)
        }
      }
      catch (e) { }
    }

    window.addEventListener("message", handler)

    return () => window.removeEventListener("message", handler)
  }, [])

  const addRoute = async (coordinates, routeName, distance) => {
    try {
      await api.post('route', {
        coordinates,
        name: routeName,
        distance,
        type: 'manual',
      });

      history.push('/admin/route');
    }
    catch (e) {
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  const html = `
  <!DOCTYPE html>
  <html>
    <head>
      <meta charset="utf-8" />
      <title>Add a line to a map using a GeoJSON source</title>
      <metaf
        name="viewport"
        content="initial-scale=1,maximum-scale=1,user-scalable=no" class=""
      />
      <link
        href="https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.css"
        rel="stylesheet"
      />
      <script src="https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.js"></script>
  
      <!-- draw function -->
      <script src="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.0.9/mapbox-gl-draw.js"></script>
      <link
        rel="stylesheet"
        href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.0.9/mapbox-gl-draw.css"
        type="text/css"
      />

      <link href='mapbox-gl-draw.css' rel='stylesheet' />
  
      <style>
        
        body {
          margin: 0;
          padding: 0;
        }
         
        .mapboxgl-ctrl-group button
        {
          width: 45px;
          height: 45px;
        }
        #map {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
         }
         
            #updateDataset {
                
                  z-index: 100;
                  background-color: #4faf53;
                  color: white;
                  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
                  padding: 8px 8px;
                  border-radius: 4px;
                  font-family: 'Helvetica Neue';
                  cursor: pointer;
                  font-size: 20px;
                  text-decoration: none;
                  text-align: center;
                  align-items: center;
                  height: auto;
                  width: auto;
              }

          
              #tracarRota {
               
                z-index: 100;
                background-color: #4faf53;
                color: white;
                box-shadow: 0 0 0 2px rgba(0,0,0,.1);
                padding: 8px 8px;
                border-radius: 4px;
                font-family: 'Helvetica Neue';
                cursor: pointer;
                font-size: 20px;
                text-decoration: none;
                text-align: center;
                align-items: center;
                height: auto;
                width: auto;
            }
  
            #controls {
              position: absolute;
              top: 20px;
              margin-left: 10px;
              left: 50%;
              width: 100%;
              min-width: 260px;
              transform: translateX(-50%);
            }

            #tracarRota{
              background: black;
            }

            #trash {
             
               z-index: 100;
                background-color: #ff0000;
                color: white;
                padding: 8px 8px;
                border-radius: 4px;
                font-family: 'Helvetica Neue';
                cursor: pointer;
                font-size: 20px;
                text-decoration: none;
                text-align: center;
                align-items: center;
                height: auto;
                width: auto;
            }
          
      </style>
    </head>
    <body>
         <div id="map"></div>
         <div id='controls'>
          <a id='updateDataset'>Salvar rota</a>
          <a id='tracarRota'>Traçar rota</a>
          <a id='trash'>Limpar</a>
        </div>
       
      <script>
          mapboxgl.accessToken = "pk.eyJ1Ijoicm9nZXJpb3dlYiIsImEiOiJjbGcxNjY0eHgwMGhhM2xxdjNyaDBxc3B5In0.KvFLopI6xct709jst-UKQA";
          var routeGenerated;
          var routeDistance;

          const map = new mapboxgl.Map({
          container: "map",
          // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
          style: "mapbox://styles/mapbox/streets-v12",
          center: [-43.18453000622236, -22.9386262680732],
          zoom: 15,
             language: 'pt',
          });

          map.addControl(
            new mapboxgl.GeolocateControl({
            positionOptions: {
            enableHighAccuracy: true
            },
            // When active the map will receive updates to the device's location as it changes.
            trackUserLocation: true,
            // Draw an arrow next to the location dot to indicate which direction the device is heading.
            showUserHeading: true
            })
            );

          const nav = new mapboxgl.NavigationControl({
            visualizePitch: true,
            showCompass: false,
            showZoom: true
          });
          //map.addControl(nav, 'top-left');

  
          map.on("load", () => {
        
                  document.getElementById('updateDataset').onclick = function(e) {
                      e.preventDefault();
                      upload();
                  }

                  document.getElementById('trash').onclick = function(e) {
                    removeRoute();
                    draw.deleteAll();
                  }
                 
                  document.getElementById('tracarRota').onclick = function(e) {
                    try {
                      document.getElementById('tracarRota').innerText='Aguarde...';
                      e.preventDefault();
                      updateRoute();
                      document.getElementById('tracarRota').innerText='Traçar rota';
                    }
                    catch(e){
                      document.getElementById('tracarRota').innerText='Traçar rota';
                    }
                  }
        });
  
        const draw = new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            line_string: true,
            trash: false,
            
          },
          defaultMode: "draw_line_string",
          styles: [
            {
              id: "gl-draw-line",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "LineString"],
                ["!=", "mode", "static"],
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round",
              },
              paint: {
                "line-color": "#438EE4",
                "line-dasharray": [0.2, 2],
                "line-width": 2,
                "line-opacity": 0.7,
              },
            },
            // Style the vertex point halos
            {
              id: "gl-draw-polygon-and-line-vertex-halo-active",
              type: "circle",
              filter: [
                "all",
                ["==", "meta", "vertex"],
                ["==", "$type", "Point"],
                ["!=", "mode", "static"],
              ],
              paint: {
                "circle-radius": 12,
                "circle-color": "#FFF",
              },
            },
            // Style the vertex points
            {
              id: "gl-draw-polygon-and-line-vertex-active",
              type: "circle",
              filter: [
                "all",
                ["==", "meta", "vertex"],
                ["==", "$type", "Point"],
                ["!=", "mode", "static"],
              ],
              paint: {
                "circle-radius": 8,
                "circle-color": "#438EE4",
              },
            },
          ],
        });
  
        map.addControl(draw);
  
        
        function updateRoute() {
          removeRoute(); // Overwrite any existing layers
  
          const profile = "cycling";
  
          // Get the coordinates
          const data = draw.getAll();
          const lastFeature = data.features.length - 1;
          const coords = data.features[lastFeature].geometry.coordinates;
          
          const newCoords = coords.join(";");
          const radius = coords.map(() => 50);
          getMatch(newCoords, radius, profile);
        }
  
        async function getMatch(coordinates, radius, profile) {
        
          const radiuses = radius.join(";");
          
          // const query = await fetch(
          //  'https://router.project-osrm.org/route/v1/foot/'+coordinates+'?continue_straight=true&overview=simplified&alternatives=false&steps=false&geometries=geojson',
          //  { method: "GET" }
          //); 

          const query = await fetch('https://api.mapbox.com/matching/v5/mapbox/walking/'+coordinates+'?steps=true&access_token=pk.eyJ1Ijoicm9nZXJpb3dlYiIsImEiOiJjbGcxNjY0eHgwMGhhM2xxdjNyaDBxc3B5In0.KvFLopI6xct709jst-UKQA&geometries=geojson');
     
  
          const response = await query.json();
    
          if (response.code !== "Ok") {
            console.error(response.message);
            return;
          }
  
          const coords = response.matchings[0].geometry;
          routeGenerated = response.tracepoints;
          routeDistance = response.matchings[0].distance;
          addRoute(coords);
        }
  
        function addRoute(coords) {
          console.log(coords);
          if (map.getSource("route")) {
            map.removeLayer("route");
            map.removeSource("route");
          } else {
            map.addLayer({
              id: "route",
              type: "line",
              source: {
                type: "geojson",
                data: {
                  type: "Feature",
                  properties: {},
                  geometry: coords,
                },
              },
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "#03AA46",
                "line-width": 8,
                "line-opacity": 0.8,
              },
            });
          }
        }
  
        // If the user clicks the delete draw button, remove the layer if it exists
        function removeRoute() {
            if (!map.getSource("route")) return;
            map.removeLayer("route");
            map.removeSource("route");
            routeGenerated = [];
        }
  
        function upload(){
            
             const coordinates = [];

             if (routeGenerated && routeGenerated.length > 0){
              for(i = 0; i < routeGenerated.length; i++){
                 coordinates.push(routeGenerated[i].location);
              }
                window.top.postMessage(
                  JSON.stringify({
                    error: false,
                    coordinates: coordinates,
                    distance: routeDistance
                  }),
                '*'
                );
             }
             else{
              alert('Especifique a rota primeiro');
             }
             

         }

         document.addEventListener("message", message => {
           var data = JSON.parse(message.data);
           if (data.position){
             map.flyTo({
              center: [data.position.lng, data.position.lat],
              zoom: 12
             });
           }

         });
 
      </script> 
    </body>
  </html> 
`;
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Cadastrar Rota Manual</h4>
            </CardHeader>
            <CardBody>
              <iframe srcDoc={html} style={{ width: '100%', height: 800 }} scrolling="no"></iframe>
              <Link to="/admin/route"> <Button color="info">VOLTAR</Button></Link>
            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
