import 'date-fns';
import { Link } from 'react-router-dom';
import { useEffect, useCallback, useState, useRef } from 'react';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Delete from '@material-ui/icons/Delete';
import Button from 'components/CustomButtons/Button.js';
import { confirmAlert } from 'react-confirm-alert'; //
import Divider from "@material-ui/core/Divider";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function EditGroup() {
  const [isLoading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [users, setUsers] = useState([]);
  const [groupPhotos, setGroupPhotos] = useState([]);
  const [user, setUser] = useState('');
  const [group, setGroup] = useState();
  const [action, setAction] = useState('update');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();
  const classes = useStyles();

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (action === "update") {
      loadData(id);
      getPhotosGroup();
    }
  }, [action]);

  const getPhotosGroup = useCallback(async () => {
    try {

      const response = await api.post("/group/member/photos", {
        group_id: id
       
      });

      const photos = [];

      response.data.photos.map(s => {
        photos.push({
          id: s.id,
          name: s.name,
          photo: s.photo,
          user: s.user
        })
      })

      setGroupPhotos(photos);
  
      setLoading(false);

    }
    catch (e) { }
  }, []);

  const getUsers = useCallback(async () => {
    try {

      const response = await api.post("/user/search", {
        filters: {
          order: 'ordem_alfabetica'
        }
      });

      const users = [];

      response.data.users.map(s => {
        users.push({
          id: s.id,
          name: s.name,
          nickname: s.nickname,
          phone: s.phone
        })
      })

      setUsers(users);
      setLoading(false);

    }
    catch (e) { }
  }, []);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'
        }
      ]
    });

  }, []);

  const handleDeleteImagem = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover a imagem ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteActionImage(id)
        },
        {
          label: 'Não'
        }
      ]
    });

  }, []);

  const handleAddUser = useCallback(async () => {
    try {

      const userAdd = users.find(u => u.id === user);

      if (userAdd) {
        await api.post(`/group/member/add`, {
          name: userAdd.nickname,
          group_id: id,
          phone: userAdd.phone
        });

        loadData();
      }
    }
    catch (e) { }

  });


  const postDeleteActionImage = useCallback(async (id) => {
    try {
      await api.delete(`/user/placephoto/${id}`);
      getPhotosGroup();
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, []);

  const postDeleteAction = useCallback(async (id) => {
    try {
      await api.delete(`/group/member/delete/${id}`);
      loadData();
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, []);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/group/${id}`);
      setGroup(response.data);


      setLoaded(true);
      setLoading(false);

    }
    catch (e) {

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

      history.push("/admin/group");
    }
  }, []);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Detalhes Grupo - {group?.name}</h4>
            </CardHeader>
            <CardBody>

              {group?.photo && <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}group/${group?.photo}`} width={200}></img>}

              <p><strong>Nome Grupo: ({group?.name}) </strong></p>

              <p><strong>Membros do Grupo ({group?.members?.length}) </strong></p>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Apelido</TableCell>
                    <TableCell>Nickname</TableCell>
                    <TableCell>Pendente</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {group?.members?.map((gm, index) => {
                    return (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{gm.user.name}</TableCell>
                        <TableCell>{gm.user.nickname}</TableCell>
                        <TableCell>Nao</TableCell>
                        <TableCell><Button color="danger" round onClick={() => handleDelete(gm.id)}><Delete /></Button></TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>

              <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 25 }}>
                <InputLabel id="user_label">Usuario</InputLabel>
                <Select
                  labelId="user"
                  label="Usuário"
                  variant="outlined"
                  name="USER"
                  fullWidth
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                >
                  <MenuItem value="T">
                    TODOS
                  </MenuItem>
                  {users && users.map(u =>
                    <MenuItem value={u.id}>
                      {u.name} - {u.nickname}
                    </MenuItem>)
                  }

                </Select>
              </FormControl>


              <Button color="primary" onClick={() => handleAddUser()}>ADICIONAR USUARIO</Button>

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />


              <p><strong>Fotos inseridas no grupo: </strong></p>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Imagem</TableCell>
                    <TableCell>Apelido</TableCell>
                    <TableCell>Nickname</TableCell>
                   
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupPhotos?.map((gm, index) => {
                    return (
                      <TableRow>
                        <TableCell><img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}place/${gm.photo}`} width={250} /></TableCell>
                        <TableCell>{gm.user.name}</TableCell>
                        <TableCell>{gm.user.nickname}</TableCell>
                        <TableCell><Button color="danger" round onClick={() => handleDeleteImagem(gm.id)}><Delete /></Button></TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>

              <Link to="/admin/group"> <Button color="info">VOLTAR</Button></Link>


            </CardBody>

          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
