import Logo from '../../assets/img/logonova.png';
import React, {useRef, useState, useCallback} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Weloc
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login() {

  const classes = useStyles();
  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <div className={classes.paper}>   

        <img src={Logo} style={{width: 150}} />

        <h3><strong>Politica de Privacidade</strong></h3>

        <br />Este aplicativo é mantido e operado por Juliano Satler Rocha.<br />
        <br />Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que
        utilizam nosso aplicativo. Ao fazê-lo, agimos na qualidade de controlador desses
        dados e estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral
        de Proteção de Dados Pessoais - LGPD).<br />
        <br />Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos
        esta política de privacidade, que contém informações importantes sobre:<br />

        - Quem deve utilizar nosso aplicativo<br />
        - Quais dados coletamos e o que fazemos com eles;<br />
        - Seus direitos em relação aos seus dados pessoais; e<br />
        - Como entrar em contato conosco.<br />
        <p>Weloc não coleta idade ou data de nascimento de seus
        usuários. Consequentemente, não está estabelecido qualquer tratamento específico
        de dados pessoais de menores. Weloc destina-se apenas a usuários maiores de 16
        anos. Se o usuário não for maior que 16 anos, não deverá se cadastrar.</p>

        <strong>1. Dados que coletamos e motivos da coleta</strong><br />
Nosso aplicativo coleta e utiliza alguns dados pessoais de nossos usuários, de
acordo com o disposto nesta seção.<br /><br />
<strong>1. Dados pessoais coletados</strong><br />
Coletamos os seguintes dados pessoais de nossos usuários:
Dados de geolocalização, endereços de IP, chat de conversas, número de telefone,
imagens e fotografias.<br /><br />
A coleta destes dados ocorre nos seguintes momentos:<br /><br />
Ao acessar o aplicativo, postar fotos ou imagens seja no chat dos grupos ou no
mapa.<br /><br />
Estes dados são coletados com as seguintes finalidades:<br /><br />
Para o pleno funcionamento do aplicativo<br /><br />
<strong>2. Dados sensíveis</strong><br />
Não serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles
definidos nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim,
não haverá coleta de dados sobre origem racial ou étnica, convicção religiosa,
opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico
ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico,
quando vinculado a uma pessoa natural.<br /><br />
<strong>3. Coleta de dados não previstos expressamente</strong><br />
Eventualmente, outros tipos de dados não previstos expressamente nesta Política
de Privacidade poderão ser coletados, desde que sejam fornecidos com o
consentimento do usuário, ou, ainda, que a coleta seja permitida com fundamento
em outra base legal prevista em lei.<br /><br />
Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes
serão informadas aos usuários do aplicativo.<br /><br />
<strong>4. Compartilhamento de dados pessoais com terceiros</strong><br />
Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é
possível que o façamos para cumprir alguma determinação legal ou regulatória, ou,
ainda, para cumprir alguma ordem expedida por autoridade pública.<br /><br />
<strong>5. Por quanto tempo seus dados pessoais serão armazenados</strong><br />
Os dados pessoais coletados pelo aplicativo são armazenados e utilizados por
período de tempo que corresponda ao necessário para atingir as finalidades
elencadas neste documento e que considere os direitos de seus titulares, os direitos
do controlador do aplicativo e as disposições legais ou regulatórias aplicáveis.
Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são
removidos de nossas bases de dados ou anonimizados, salvo nos casos em que
houver a possibilidade ou a necessidade de armazenamento em virtude de
disposição legal ou regulatória.<br /><br />
<strong>6. Bases legais para o tratamento de dados pessoais</strong><br />
Cada operação de tratamento de dados pessoais precisa ter um fundamento
jurídico, ou seja, uma base legal, que nada mais é que uma justificativa que a
autorize, prevista na Lei Geral de Proteção de Dados Pessoais.<br /><br />
Todas as Nossas atividades de tratamento de dados pessoais possuem uma base
legal que as fundamenta, dentre as permitidas pela legislação. Mais informações
sobre as bases legais que utilizamos para operações de tratamento de dados
pessoais específicas podem ser obtidas a partir de nossos canais de contato,
informados ao final desta Política.<br /><br />
<strong>7. Direitos do usuário</strong><br />
O usuário do aplicativo possui os seguintes direitos, conferidos pela Lei de Proteção
de Dados Pessoais:<br /><br />
- confirmação da existência de tratamento;<br />
- acesso aos dados;<br />
- correção de dados incompletos, inexatos ou desatualizados;<br />
- nonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou
tratados em desconformidade com o disposto na lei;<br />
- portabilidade dos dados a outro fornecedor de serviço ou produto, mediante
requisição expressa, de acordo com a regulamentação da autoridade nacional,
observados os segredos comercial e industrial;<br />
- eliminação dos dados pessoais tratados com o consentimento do titular, exceto
nos casos previstos em lei;<br />
- informação das entidades públicas e privadas com as quais o controlador
realizou uso compartilhado de dados;<br />
- informação sobre a possibilidade de não fornecer consentimento e sobre as
consequências da negativa;<br />
- revogação do consentimento.<br /><br />
É importante destacar que, nos termos da LGPD, não existe um direito de
eliminação de dados tratados com fundamento em bases legais distintas do
consentimento, a menos que os dados seja desnecessários, excessivos ou tratados
em desconformidade com o previsto na lei.<br /><br />
<strong>7.1 Como o titular pode exercer seus direitos</strong><br />
Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular
dos dados pessoais objeto da requisição, poderemos solicitar documentos ou outras
informações que possam auxiliar em sua correta identificação, a fim de resguardar
nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for
absolutamente necessário, e o requerente receberá todas as informações
relacionadas.<br /><br />
<strong>8. Medidas de segurança no tratamento de dados pessoais</strong><br />
Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais
de acessos não autorizados e de situações de destruição, perda, extravio ou
alteração desses dados.<br /><br />
As medidas que utilizamos levam em consideração a natureza dos dados, o
contexto e a finalidade do tratamento, os riscos que uma eventual violação geraria
para os direitos e liberdades do usuário, e os padrões atualmente empregados no
mercado por empresas semelhantes à nossa.<br /><br />
Entre as medidas de segurança adotadas por nós, destacamos as seguintes:
Armazenamento de senhas usando hashs criptográficos, encriptação dos dados
sensíveis dos usuários., monitoramento e prevenção de ataques de DDOS,
limitando o número de acessos da mesma origem. Uso de sessões com uso de
tokens JWT com tempo limitado e atualizações para evitar fraude nas sessões
dos usuários. <br /><br />
Ainda que adote tudo o que está ao seu alcance para evitar incidentes de
segurança, é possível que ocorra algum problema motivado exclusivamente por um
terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de
culpa exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere
seus dados a terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados
pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação
excepcional como essas, sobre as quais não temos nenhum tipo de controle.<br /><br />
De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa
gerar risco ou dano relevante para qualquer de nossos usuários, comunicaremos os
afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em
conformidade com o disposto na Lei Geral de Proteção de Dados.<br /><br />
<strong>9. Reclamação a uma autoridade de controle</strong><br />
Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, os titulares
de dados pessoais que se sentirem, de qualquer forma, lesados, podem apresentar
reclamação à Autoridade Nacional de Proteção de Dados.<br /><br />
<strong>10. Alterações nesta política</strong><br />
A presente versão desta Política de Privacidade foi atualizada pela última vez em:
27/11/2023.<br /><br />
Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas,
especialmente para adaptá-las às eventuais alterações feitas em nosso aplicativo,
seja pela disponibilização de novas funcionalidades, seja pela supressão ou
modificação daquelas já existentes.<br /><br />
Sempre que houver uma modificação, nossos usuários serão notificados acerca da
mudança.<br /><br />
<strong>11. Como entrar em contato conosco</strong><br />
Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os
dados pessoais que tratamos, entre em contato com nosso Encarregado de
Proteção de Dados Pessoais, por algum dos canais mencionados abaixo:<br />
E-mail: julianosatler@yahoo.com.br<br />
Telefone: +55 21 99372 1465<br />
Endereço postal: Rua figueiredo Magalhaes 771. Copacabana, Rio de Janeiro / RJ



        
     
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}