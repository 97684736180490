import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import 'assets/css/react-confirm-alert.css'; // Import css

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { confirmAlert } from 'react-confirm-alert';

import { Pagination } from '@mui/material';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function ContentControl() {

  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const {addToast} = useToasts();

  const getData = useCallback(async () => {
    try {

      const response = await api.post("/general/content_control/search", {
        page,
        filters: {
            usoabusivo: 'S'
        }
      });

      setTotal(response.data.total);

      const info = [];

      response.data.data.map(s => {
        info.push({
          id: s.id,
          user_owner: s.place.user.name,
          user_complaint: s.user.name,
          motivo: s.motivo,
          name: s.place.name,
          place: s.place.photo,
          place_id: s.place.id,
          created_at: new Date(s.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
        })
      })

      setData(info);
      setLoading(false);
    }
    catch(e){
      setLoading(false);
  
     
    } 
  },[]);


  const handleDeletePlace = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este conteudo ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeletePlaceAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);
 
  const handleDelete = useCallback(async (id) => {
 
    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover esta reclamação ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });
  });


  const postDeletePlaceAction =  useCallback(async (id) => {
      try {


        await api.delete(`/user/placephoto/${id}`);
        getData();
        
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  const postDeleteAction =  useCallback(async (id) => {
    try {
      await api.delete(`/general/content_control/${id}`);
      getData();
      
    }
    catch(e){
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });        
    }
},[]);


   useEffect(() => {
      getData();
   }, [page]);
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Reclamação de conteudo impróprio</h4>
            <p className={classes.cardCategoryWhite}>
            Reclamação de Conteudos - Uso indevido
            </p>
          </CardHeader>
          <CardBody>
                
          {total > 50 && <Pagination page={page} onChange={(ev, page) => {setPage(page)}} count={total} size="large" style={{marginTop:30}}/>}    
     
          {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['', 'Descrição', 'Usuário que postou', 'Usuário reclamante', 'Motivo', 'Criado em', '', '']}
              tableColumn={['place', 'name', 'user_owner', 'user_complaint', 'motivo', 'created_at', 'delete_complaint', 'delete_place']}
              tableColumnSize={['1%', '30%', '20%', '20%', '20%', '20%']}
              tableData={data}         
              onDeletePlace={(id) => {
                handleDeletePlace(id);
              }}
              onDelete={(id) => {


                handleDelete(id);
              }}
           
           /> : isLoading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
          ) : (           
            <p style={{marginTop: 20}}>Nenhum registro cadastrado.</p>
          )}

          {total > 50 && <Pagination page={page} onChange={(ev, page) => {setPage(page)}} count={total} size="large" style={{marginTop:30}}/>}
                    
                    
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
