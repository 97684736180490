import React, {useRef, useState, useCallback} from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'

import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationErrors';
import Logo from '../../assets/img/logonova.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Weloc
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (e) => {
    
      try {

        e.preventDefault();

        const email = e.target.email.value;
        const password = e.target.password.value; 

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatóario')
            .email('Digite um email valido'),
          password: Yup.string().required('Senha obrigatória')
        });

        await schema.validate({email, password}, {
          abortEarly: false,
        });

        await signIn({
          email: email,
          password: password,
        });

        history.push('/admin/admin');

      } catch (err) {

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err); 

          for (var [, value] of Object.entries(errors)) {
            addToast(value, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
          return;

        }    

        addToast(err.response && err.response.data ? err.response.data.message : 'Ocorreu um erro !', {
          appearance: 'error',
          autoDismiss: true,
        });        
           
      }
    },
    [signIn, history],
  );
  

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>   

        <img src={Logo} style={{width: 150}} />
      
        <form className={classes.form} ref={formRef} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
          />
           <Button 
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            style={{backgroundColor: '#597f20'}}
            className={classes.submit}
          >
            Entrar
          </Button> 
        </form> 
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}